  .loader{
    position: absolute;
    top:calc(50% - 50px);
    left:calc(50% - 50px);
    width: 100px;
    height: 100px;
    z-index: 1;
  }

  .loadercwr{
    animation: clockwiserotate 1.5s infinite linear;
  }
  
  .loaderccwr{
    animation: counterclockwiserotate 1.5s infinite linear;
  }

  @keyframes clockwiserotate {
    0% {   transform: rotate(0deg);}
    100% { transform: rotate(359deg);}
  }
  
  @keyframes counterclockwiserotate {
    0% {    transform: rotate(0deg);}
    100% {  transform: rotate(-359deg);}
  }

  