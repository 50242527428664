:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --link-color: #1a0dab;
  /* Header */
  --background-color-header: #e0e0e0;
}

body {
  margin: 0;
  padding: 0;
  overflow:hidden;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  background-color: var(--background-color-header);
  border-bottom: 1px solid var(--text-color);
  padding: 5px;
  font-style:italic;
}


nav {
  background-color: var(--background-color);
}

button {
  background-color: var(--link-color);
  color: var(--background-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: var(--text-color);
  color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-card{
  border:solid 1px gray; 
  padding:25px;
}

.login{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}

.login input{
  border: 1px solid darkgray;
  margin:8px;
  padding: 8px;
}

.label{
  width:7ex;
  display: inline-block;
}

.field{
  display: inline-block;
}